import "core-js/modules/es6.function.name";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { ContactLinks, Link } from 'components';
import FactList from './FactList';
import { className, getTeamMemberRoute } from 'utils';
import styles from './teamMember.module.scss';
var BGCOLOR = styles.varBgColor;

var TeamMember = function TeamMember(_ref) {
  var email = _ref.email,
      linkedIn = _ref.linkedIn,
      medium = _ref.medium,
      name = _ref.name,
      quickFacts = _ref.quickFacts,
      portrait = _ref.portrait,
      role = _ref.role,
      slug = _ref.slug,
      twitter = _ref.twitter;

  /**
   * Memoized value for whether this team member has a portrait
   * photo.
   */
  var hasPortrait = useMemo(function () {
    return portrait && portrait.fluid;
  }, [portrait]);
  /**
   * Memoized value for the individual team member's route.
   */

  var teamMemberRoute = useMemo(function () {
    return getTeamMemberRoute(slug);
  }, [slug]);
  return React.createElement("div", className(hasPortrait ? styles.member : styles.memberNoPhoto), hasPortrait && React.createElement(Link, {
    to: teamMemberRoute,
    className: styles.memberPhotoLink
  }, React.createElement(Img, {
    alt: portrait.title,
    backgroundColor: BGCOLOR,
    className: styles.memberPhoto,
    fluid: portrait.fluid
  })), React.createElement("h3", {
    className: styles.memberName
  }, React.createElement(Link, {
    to: teamMemberRoute,
    className: styles.memberLink
  }, name)), React.createElement("h4", {
    className: styles.memberRole
  }, role), React.createElement(FactList, {
    className: styles.factList,
    facts: quickFacts
  }), React.createElement(ContactLinks, {
    className: styles.memberContact,
    linkClassName: styles.memberContactLink,
    email: email,
    linkedIn: linkedIn,
    medium: medium,
    twitter: twitter
  }));
};

TeamMember.propTypes = {
  email: PropTypes.string,
  linkedIn: PropTypes.string,
  medium: PropTypes.string,
  name: PropTypes.string.isRequired,
  quickFacts: PropTypes.arrayOf(PropTypes.string).isRequired,
  portrait: PropTypes.shape({
    fluid: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
  }),
  role: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  twitter: PropTypes.string
};
export default TeamMember;