import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { className, getFullHeight, getTeamMemberRoute, renderText } from 'utils';
import styles from './portfolioListItem.module.scss';
var MOBILE_BREAKPOINT = parseInt(styles.varMobileBreakpoint);
var COLLAPSE_DELAY = parseInt(styles.varCollapseDelay);
var COLLAPSE_DURATION = parseInt(styles.varCollapseDuration);
var EXPAND_DURATION = parseInt(styles.varExpandDuration); // eslint-disable-next-line react/display-name

var PortfolioListItem = forwardRef(function (_ref, ref) {
  var description = _ref.description,
      founder = _ref.founder,
      isActive = _ref.isActive,
      isLast = _ref.isLast,
      isLoaded = _ref.isLoaded,
      location = _ref.location,
      logo = _ref.logo,
      name = _ref.name,
      onClick = _ref.onClick,
      onMouseEnter = _ref.onMouseEnter,
      partners = _ref.partners,
      website = _ref.website;
  var companyNameRef = useRef();
  var detailWrapperRef = useRef();
  var detailElRef = useRef();
  /**
   * Memoized value for the tab index of all links the detail section.
   * Removes them from tab order when the item is not active.
   */

  var linkTabIndex = useMemo(function () {
    return isActive ? 0 : -1;
  }, [isActive]);
  /**
   * Memoized value for the rendered name of the company, with every
   * word split into `span`s.
   */

  var renderedName = useMemo(function () {
    var nameWords = name.split(' ');

    if (nameWords.length === 1) {
      return nameWords;
    }

    return nameWords.map(function (text, i) {
      return React.createElement(Fragment, {
        key: i
      }, React.createElement("span", null, text), i < nameWords.length - 1 && ' ');
    });
  });
  /**
   * Memoized value for the website text, that removes the prefix
   * of the required URL regex (i.e.: `https://www.`, `http://`).
   */

  var websiteText = useMemo(function () {
    return website ? website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0] : '';
  }, [website]);
  /**
   * Updates the height of the detail wrapper on resize.
   */

  var onResize = function onResize() {
    var wrapper = detailWrapperRef.current;

    if (window.innerWidth >= MOBILE_BREAKPOINT) {
      wrapper.removeAttribute('style');
    } else {
      wrapper.style.height = getFullHeight(detailElRef.current) + "px";

      if (isActive) {
        setTimeout(function () {
          wrapper.style.height = 'auto';
        }, EXPAND_DURATION);
      } else {
        setTimeout(function () {
          wrapper.style.height = 0;
          setTimeout(function () {
            wrapper.removeAttribute('style');
          }, COLLAPSE_DURATION);
        }, COLLAPSE_DELAY);
      }
    }
  };

  useEffect(function () {
    if (detailWrapperRef.current && detailElRef.current) {
      onResize();
      window.addEventListener('resize', onResize);
      return function () {
        window.removeEventListener('resize', onResize);
      };
    }
  }, [isActive]);
  useImperativeHandle(ref, function () {
    return {
      link: companyNameRef.current,
      content: detailElRef.current
    };
  }, [companyNameRef, detailElRef]);
  return React.createElement("li", className(styles.portfolioListItem, isActive && styles.active, isLoaded && styles.loaded, isLast && styles.lastItem), React.createElement("div", {
    className: styles.portfolioListLeftColumn
  }, React.createElement("div", {
    className: styles.portfolioListLeftColumnTextWrapper,
    ref: companyNameRef
  }, React.createElement("button", {
    className: styles.companyName,
    onClick: onClick,
    onMouseEnter: onMouseEnter,
    onFocus: onClick
  }, renderedName))), React.createElement("div", {
    className: styles.portfolioListRightColumnWrapper,
    ref: detailWrapperRef
  }, React.createElement("div", {
    className: styles.portfolioListRightColumn,
    ref: detailElRef
  }, logo && React.createElement("img", {
    src: logo.file.url,
    alt: logo.title,
    className: styles.logo
  }), description && React.createElement("div", {
    className: styles.description
  }, renderText(description)), React.createElement("div", {
    className: styles.metadata
  }, website && React.createElement("div", {
    className: styles.metadataGroup
  }, React.createElement("span", {
    className: styles.metadataTitle
  }, "Website"), React.createElement(Link, {
    to: website,
    className: styles.metadataLink,
    newTab: true,
    tabIndex: linkTabIndex
  }, websiteText)), React.createElement("div", {
    className: styles.metadataGroup
  }, React.createElement("span", {
    className: styles.metadataTitle
  }, "Location"), React.createElement("span", {
    className: styles.locationFull
  }, location.name)), founder && React.createElement("div", {
    className: styles.metadataGroup
  }, React.createElement("span", {
    className: styles.metadataTitle
  }, "Founder"), React.createElement("span", null, founder)), partners && partners.length > 0 && React.createElement("div", {
    className: styles.metadataGroup
  }, React.createElement("span", {
    className: styles.metadataTitle
  }, "Hashed Partner", partners.length > 1 && 's'), React.createElement("span", null, partners.map(function (_ref2, i) {
    var name = _ref2.name,
        slug = _ref2.slug;
    return React.createElement("span", {
      key: i
    }, React.createElement(Link, {
      to: getTeamMemberRoute(slug),
      className: styles.metadataLink,
      tabIndex: linkTabIndex
    }, name), i < partners.length - 1 && ', ');
  })))))));
});
PortfolioListItem.propTypes = {
  description: PropTypes.shape({
    json: PropTypes.object.isRequired
  }).isRequired,
  founder: PropTypes.string,
  isActive: PropTypes.bool,
  isLast: PropTypes.bool,
  isLoaded: PropTypes.bool,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  logo: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired
  }),
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  partners: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })),
  website: PropTypes.string
};
PortfolioListItem.defaultProps = {
  isActive: false,
  isLoaded: false
};
export default PortfolioListItem;