import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './homepageSection.module.scss';

var HomepageSection = function HomepageSection(_ref) {
  var children = _ref.children,
      isMedia = _ref.isMedia;
  return React.createElement("section", className(isMedia ? styles.pageSectionMedia : styles.pageSectionStatement), React.createElement("div", {
    className: styles.sectionContent
  }, children));
};

HomepageSection.propTypes = {
  children: PropTypes.node,
  isMedia: PropTypes.bool
};
HomepageSection.defaultProps = {
  isMedia: false
};
export default HomepageSection;