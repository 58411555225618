import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

/**
 * Process the given actions per module to create
 * scoped actions that work within the given scope.
 *
 * @param {string} scopedName the name of the module to scope
 * actions to
 * @param {object} actions the actions to scope
 * @returns the object of scoped actions
 */
var getScopedActions = function getScopedActions(scopedName, actions) {
  var types = Object.keys(actions);
  var scopedActions = types.reduce(function (acc, type) {
    var _Object$assign2;

    var scopedAction = function scopedAction(globalState, action) {
      var _Object$assign;

      var scopedState = globalState[scopedName];
      var scopedStateAfterAction = actions[type](scopedState, action, globalState);
      return Object.assign({}, globalState, (_Object$assign = {}, _Object$assign[scopedName] = scopedStateAfterAction, _Object$assign));
    };

    return Object.assign({}, acc, (_Object$assign2 = {}, _Object$assign2[type] = scopedAction, _Object$assign2));
  }, {});
  return scopedActions;
};
/**
 * Creates a new store, with an initial state and reducer.
 * Builds the store from an array of store modules.
 *
 * @param {object[]} [modules=[]] the array of modules to build the
 * store from
 * @returns {[object, function]} the store initial state and reducer
 */


var createStore = function createStore(modules) {
  if (modules === void 0) {
    modules = [];
  }

  var initialState = {};
  var reducerCases = {};
  modules.forEach(function (_ref) {
    var name = _ref.name,
        state = _ref.initialState,
        actions = _ref.actions;
    initialState[name] = state;
    reducerCases = Object.assign({}, reducerCases, getScopedActions(name, actions));
  });
  /**
   * The reducer for the entire store. Accepts actions from
   * a dispatcher and updates the store accordingly.
   *
   * @param {object} state the current state of the store
   * @param {object} action the action that was dispatched
   * @param {string} action.type the type of action that
   * was dispatched. Used to narrow which reducer to use
   * @param {any} action.payload additional information
   * about the action to provide more context to the reducer
   */

  var reducer = function reducer(state, action) {
    var matchCase = reducerCases[action.type];

    if (matchCase) {
      return matchCase(state, action);
    }

    return state;
  };

  return [initialState, reducer];
};

export default createStore;