import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Grid, PortfolioListItem } from 'components';
import { useArrayRef } from 'hooks';
import { className } from 'utils';
import styles from './portfolioGroup.module.scss'; // eslint-disable-next-line react/display-name

var PortfolioGroup = forwardRef(function (_ref, ref) {
  var activeItem = _ref.activeItem,
      companies = _ref.companies,
      index = _ref.index,
      isActive = _ref.isActive,
      isLast = _ref.isLast,
      isLoaded = _ref.isLoaded,
      title = _ref.title,
      updateView = _ref.updateView;

  var _useArrayRef = useArrayRef(),
      portfolioItemsRef = _useArrayRef[0],
      setPortfolioItemRef = _useArrayRef[1];
  /**
   * Returns a callback function on list item update. Updates
   * the highlighter view in the parent portfolio page.
   *
   * @param {number} i the item index
   * @param {boolean} [desktopOnly=false] whether the event
   * should trigger on desktop only
   * @returns {function} the callback function to pass
   * to the event handler
   */


  var onUpdate = function onUpdate(i, desktopOnly) {
    if (desktopOnly === void 0) {
      desktopOnly = false;
    }

    return function () {
      updateView(index, i, desktopOnly);
    };
  };

  useImperativeHandle(ref, function () {
    return portfolioItemsRef.current;
  }, []);
  return React.createElement("section", {
    className: styles.portfolioGroup
  }, React.createElement(Grid, {
    tag: "header",
    className: styles.portfolioGroupHeader
  }, React.createElement("h1", {
    className: styles.portfolioGroupTitle
  }, title)), React.createElement(Grid, Object.assign({
    tag: "ul"
  }, className(styles.portfolioList, isActive && styles.active)), companies.map(function (_ref2, i) {
    var description = _ref2.description,
        founder = _ref2.founder,
        location = _ref2.location,
        logo = _ref2.logo,
        name = _ref2.name,
        partners = _ref2.partners,
        website = _ref2.website;
    return React.createElement(PortfolioListItem, {
      key: i,
      ref: setPortfolioItemRef(i),
      description: description,
      founder: founder,
      isActive: isActive && i === activeItem,
      isLast: isLast && i === companies.length - 1,
      isLoaded: isLoaded,
      location: location,
      logo: logo,
      name: name,
      onClick: onUpdate(i, false),
      onMouseEnter: onUpdate(i, true),
      partners: partners,
      website: website
    });
  })));
});
PortfolioGroup.propTypes = {
  activeItem: PropTypes.number.isRequired,
  companies: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  isLast: PropTypes.bool,
  isLoaded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  updateView: PropTypes.func.isRequired
};
PortfolioGroup.defaultProps = {
  isActive: false,
  isLast: false,
  isLoaded: false
};
export default PortfolioGroup;