var _ENTRY_PAGE_TYPE_TO_R;

export var ENTRY_TYPES = {
  ABOUT_PAGE: 'aboutPage',
  CONTENT_PAGE: 'contentPage',
  HOME_PAGE: 'homePage',
  NOT_FOUND_PAGE: 'notFoundPage',
  PORTFOLIO_PAGE: 'portfolioPage',
  TEAM_MEMBER: 'teamMember',
  TEAM_PAGE: 'teamPage'
};
export var ENTRY_PAGE_TYPE_TO_ROUTE = (_ENTRY_PAGE_TYPE_TO_R = {}, _ENTRY_PAGE_TYPE_TO_R[ENTRY_TYPES.ABOUT_PAGE] = '/about', _ENTRY_PAGE_TYPE_TO_R[ENTRY_TYPES.CONTENT_PAGE] = '/content', _ENTRY_PAGE_TYPE_TO_R[ENTRY_TYPES.HOME_PAGE] = '/', _ENTRY_PAGE_TYPE_TO_R[ENTRY_TYPES.PORTFOLIO_PAGE] = '/portfolio', _ENTRY_PAGE_TYPE_TO_R[ENTRY_TYPES.TEAM_PAGE] = '/team', _ENTRY_PAGE_TYPE_TO_R);
export var KEY_CODES = {
  ESCAPE: 27
};
export var LANG = {
  EN_US: 'en-US'
};
export var STORE_MODULES = {
  NAV: 'nav'
};