/**
 * Retrieves the full height of an element, including
 * the margin top and bottom.
 *
 * @param {Object} element the element to get height of
 * @returns the full height of the element
 */
var getFullHeight = function getFullHeight(element) {
  var styles = window.getComputedStyle(element);
  var height = ['top', 'bottom'].map(function (position) {
    return parseInt(styles.getPropertyValue("margin-" + position));
  }).reduce(function (total, curr) {
    return total + curr;
  }, element.offsetHeight);
  return height;
};

export default getFullHeight;