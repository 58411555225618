/**
 * Gets the route for a team member page.
 *
 * @param {string} slug the team member slug
 * @returns {string} the team member page route
 */
var getTeamMemberRoute = function getTeamMemberRoute(slug) {
  return "/team/" + slug;
};

export default getTeamMemberRoute;