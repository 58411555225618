import React from 'react';
import PropTypes from 'prop-types';
import sharedStyles from 'svgs/sharedStyles.module.scss';

var LinkedIn = function LinkedIn(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", {
    className: customClassName
  }, React.createElement("svg", {
    className: sharedStyles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22 22"
  }, React.createElement("path", {
    d: "M6.56289308,19 L3.19886193,19 L3.19886193,8.87900569 L6.56289308,8.87900569 L6.56289308,19 Z M4.88086253,7.49715484 L4.88080264,7.49715484 L4.8589398,7.49715484 C3.73000898,7.49715484 3,6.71997604 3,5.74872716 C3,4.75561545 3.75238095,4 4.90311471,4 C6.05396825,4 6.76226415,4.75561545 6.78412698,5.74872716 C6.78412698,6.71997604 6.05396825,7.49715484 4.88086253,7.49715484 Z M8.42431866,19 C8.42431866,19 8.46855346,9.82869122 8.42431866,8.87900569 L11.7884097,8.87900569 L11.7884097,10.3468104 L11.7659479,10.3468104 C12.2085954,9.655885 13.005301,8.64150943 14.8200958,8.64150943 C17.0333633,8.64150943 18.6929919,10.0880503 18.6929919,13.1967655 L18.6929919,19 L15.3292603,19 L15.3292603,13.5855046 C15.3292603,12.2249176 14.8423181,11.2967954 13.6250374,11.2967954 C12.6957173,11.2967954 12.1421983,11.9227314 11.8990117,12.5271039 C11.8101827,12.7433363 11.7883199,13.0455226 11.7883199,13.3480084 L11.7883199,19 L8.42431866,19 Z"
  })));
};

LinkedIn.propTypes = {
  className: PropTypes.string
};
export default LinkedIn;