// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-templates-team-member-index-js": function componentSrcTemplatesTeamMemberIndexJs() {
    return import("/opt/build/repo/src/templates/TeamMember/index.js"
    /* webpackChunkName: "component---src-templates-team-member-index-js" */
    );
  },
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/opt/build/repo/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/opt/build/repo/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  },
  "component---src-pages-about-js": function componentSrcPagesAboutJs() {
    return import("/opt/build/repo/src/pages/about.js"
    /* webpackChunkName: "component---src-pages-about-js" */
    );
  },
  "component---src-pages-content-js": function componentSrcPagesContentJs() {
    return import("/opt/build/repo/src/pages/content.js"
    /* webpackChunkName: "component---src-pages-content-js" */
    );
  },
  "component---src-pages-portfolio-js": function componentSrcPagesPortfolioJs() {
    return import("/opt/build/repo/src/pages/portfolio.js"
    /* webpackChunkName: "component---src-pages-portfolio-js" */
    );
  },
  "component---src-pages-team-js": function componentSrcPagesTeamJs() {
    return import("/opt/build/repo/src/pages/team.js"
    /* webpackChunkName: "component---src-pages-team-js" */
    );
  }
};