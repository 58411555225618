import "core-js/modules/es6.object.assign";

var _actions;

import { UPDATE_NAV_INTRO } from 'store/types';
import { STORE_MODULES } from 'utils/constants';
export default {
  name: STORE_MODULES.NAV,
  initialState: {
    intro: null
  },
  actions: (_actions = {}, _actions[UPDATE_NAV_INTRO] = function (state, action) {
    return Object.assign({}, state, {
      intro: action.payload
    });
  }, _actions)
};