import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import staticQueryData from "../../../public/static/d/1782437305.json";
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { Link } from 'components';
import { CursorExternal, Hash } from 'svgs';
import { getTeamMemberRoute } from 'utils';
import styles from './post.module.scss';
var BGCOLOR = styles.varBgColor;

var Post = function Post(_ref) {
  var author = _ref.author,
      link = _ref.link,
      linkText = _ref.linkText,
      previewImage = _ref.previewImage,
      title = _ref.title;
  var defaultLinkText = staticQueryData.data.site.defaultLinkText;
  /**
   * Memozied value for the link to the author's team member page.
   */

  var authorLink = useMemo(function () {
    return author ? getTeamMemberRoute(author.slug) : '';
  }, [author]);
  /**
   * Memoized value for the portrait photo of the author.
   */

  var authorPortrait = useMemo(function () {
    return author && author.portrait;
  }, [author]);
  return React.createElement("article", {
    className: styles.post
  }, React.createElement(Link, {
    to: link,
    newTab: true,
    className: styles.postLink
  }, previewImage && React.createElement("div", {
    className: styles.imageWrapper
  }, React.createElement(Img, {
    backgroundColor: BGCOLOR,
    className: styles.postImage,
    fluid: previewImage.fluid,
    alt: previewImage.title,
    objectFit: "cover"
  })), React.createElement("div", {
    className: styles.postTitleWrapper
  }, React.createElement("h3", {
    className: styles.postTitle
  }, React.createElement("span", {
    className: styles.postTitleText
  }, title)), React.createElement(CursorExternal, {
    className: styles.cursor
  }))), React.createElement("div", {
    className: styles.postDetails
  }, React.createElement("div", {
    className: styles.avatarWrapper
  }, authorPortrait ? React.createElement(Link, {
    to: authorLink
  }, React.createElement("img", {
    src: authorPortrait.file.url,
    alt: authorPortrait.title,
    className: styles.postAuthorAvatar
  })) : React.createElement(Hash, {
    className: styles.postAvatar
  })), React.createElement("p", null, author ? React.createElement(Fragment, null, React.createElement("span", {
    className: styles.linkText
  }, "by "), React.createElement(Link, {
    to: authorLink,
    className: styles.postDetailsLink
  }, author.name)) : React.createElement("span", {
    className: styles.linkText
  }, React.createElement(Link, {
    to: link,
    newTab: true,
    className: styles.postDetailsLink
  }, linkText || defaultLinkText)))));
};

Post.defaultProps = {
  author: null,
  linkText: null,
  previewImage: null
};
Post.propTypes = {
  author: PropTypes.object,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  previewImage: PropTypes.object,
  title: PropTypes.string.isRequired
};
export default Post;