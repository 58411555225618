import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { storeContext } from 'store';
import modules from './modules';
import createStore from './createStore';

var _createStore = createStore(modules),
    initialState = _createStore[0],
    reducer = _createStore[1];

var StoreProvider = function StoreProvider(_ref) {
  var children = _ref.children;

  var _useReducer = useReducer(reducer, initialState),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  var value = [state, dispatch];
  return React.createElement(storeContext.Provider, {
    value: value
  }, children);
};

StoreProvider.propTypes = {
  children: PropTypes.node
};
export default StoreProvider;