import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './arrow.module.scss';

var Arrow = function Arrow(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", className(styles.container, customClassName), React.createElement("svg", {
    className: styles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 20"
  }, React.createElement("path", {
    className: styles.stem,
    d: "M8,12 L4,12 L-4.4408921e-16,12 L-4.4408921e-16,8 L4,8 L8,8 L12,8 L12,12 L8,12 Z"
  }), React.createElement("path", {
    className: styles.arrow,
    d: "M16,16 L16,20 L12,20 L12,16 L12,12 L12,8 L12,4 L12,0 L16,0 L16,4 L20,4 L20,8 L24,8 L24,12 L20,12 L20,16 L16,16 Z"
  })));
};

Arrow.propTypes = {
  className: PropTypes.string
};
export default Arrow;