import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './factList.module.scss';

var FactList = function FactList(_ref) {
  var customClassName = _ref.className,
      facts = _ref.facts;
  return React.createElement("div", className(styles.facts, customClassName), React.createElement("ul", {
    className: styles.factList
  }, facts.map(function (fact, key) {
    return React.createElement("li", {
      key: key,
      className: styles.fact
    }, fact);
  })));
};

FactList.propTypes = {
  className: PropTypes.string,
  facts: PropTypes.array
};
FactList.defaultProps = {
  facts: []
};
export default FactList;