import { useMemo } from 'react';
/**
 * React hook that determines if SSR is being used
 * to render the page or not.
 *
 * @returns whether the page is rendered with SSR
 */

var useDetectSSR = function useDetectSSR() {
  var isSSR = useMemo(function () {
    return typeof window === 'undefined';
  }, []);
  return isSSR;
};

export default useDetectSSR;