import "core-js/modules/es6.object.assign";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import { Email, LinkedIn, Medium, Twitter, YouTube } from 'svgs';
import { className } from 'utils';
import styles from './contactLinks.module.scss';

var ContactLinks = function ContactLinks(_ref) {
  var customClassName = _ref.className,
      email = _ref.email,
      linkClassName = _ref.linkClassName,
      linkedIn = _ref.linkedIn,
      medium = _ref.medium,
      twitter = _ref.twitter,
      youtube = _ref.youtube;
  return React.createElement("div", className(styles.contactList, customClassName), email && React.createElement(Link, Object.assign({}, className(styles.contactLink, linkClassName), {
    to: "mailto:" + email,
    title: "Email"
  }), React.createElement(Email, {
    className: styles.icon
  }), React.createElement("span", {
    className: styles.visuallyHidden
  }, "Email")), linkedIn && React.createElement(Link, Object.assign({}, className(styles.contactLink, linkClassName), {
    to: linkedIn,
    title: "LinkedIn",
    newTab: true
  }), React.createElement(LinkedIn, {
    className: styles.icon
  }), React.createElement("span", {
    className: styles.visuallyHidden
  }, "LinkedIn")), medium && React.createElement(Link, Object.assign({}, className(styles.contactLink, linkClassName), {
    to: medium,
    title: "Medium",
    newTab: true
  }), React.createElement(Medium, {
    className: styles.icon
  }), React.createElement("span", {
    className: styles.visuallyHidden
  }, "Medium")), twitter && React.createElement(Link, Object.assign({}, className(styles.contactLink, linkClassName), {
    to: twitter,
    title: "Twitter",
    newTab: true
  }), React.createElement(Twitter, {
    className: styles.icon
  }), React.createElement("span", {
    className: styles.visuallyHidden
  }, "Twitter")), youtube && React.createElement(Link, Object.assign({}, className(styles.contactLink, linkClassName), {
    to: youtube,
    title: "YouTube",
    newTab: true
  }), React.createElement(YouTube, {
    className: styles.icon
  }), React.createElement("span", {
    className: styles.visuallyHidden
  }, "YouTube")));
};

ContactLinks.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  linkClassName: PropTypes.string,
  linkedIn: PropTypes.string,
  medium: PropTypes.string,
  twitter: PropTypes.string,
  youtube: PropTypes.string
};
export default ContactLinks;