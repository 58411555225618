import "core-js/modules/es6.object.assign";
import React, { forwardRef, useImperativeHandle, useRef, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDetectSSR, useRenderedStyle } from 'hooks';
import { className, renderText } from 'utils';
import styles from './figure.module.scss';
var MAX_ROTATION = -50;
var MOBILE_BREAKPOINT = parseInt(styles.varMobileBreakpoint);
/**
 * Utility function for generating a random float value
 * between the given minimum and maximum values.
 *
 * @param {number} min the minimum value for the float
 * @param {number} max the maximum value for the float
 * @returns {number} the random float value between
 * the given max and min
 */

var getRandomFloat = function getRandomFloat(min, max) {
  return (Math.random() * (max - min) + min).toFixed(2);
};
/**
 * Re-maps a given number from one range to another range.
 *
 * @param {number} value the value to be converted
 * @param {number} low1 the lower value of the current range
 * @param {number} high1 the upper value of the current range
 * @param {number} low2 the lower value of the target range
 * @param {number} high2 the upper value of the target range
 * @returns the given value re-mapped to the new range
 */


var map = function map(value, low1, high1, low2, high2) {
  return (value - low1) * (high2 - low2) / (high1 - low1) + low2;
};

var Figure = forwardRef(function (_ref, ref) {
  var alt = _ref.alt,
      captionBody = _ref.captionBody,
      captionHeading = _ref.captionHeading,
      _ref$image = _ref.image,
      url = _ref$image.url,
      image = _ref$image.details.image,
      intensity = _ref.intensity,
      isLoaded = _ref.isLoaded;
  var isSSR = useDetectSSR();
  /**
   * References for the image wrapper, caption and image elements.
   */

  var imgWrapperRef = useRef();
  var imgRef = useRef();
  var captionRef = useRef();
  /**
   * Reference values for the observer and viewport visibility.
   */

  var observer = useRef(null);
  var isInsideViewport = useRef(false);
  var isVisible = useRef(false);
  /**
   * Reference value for whether the window is a mobile size.
   */

  var isMobile = useRef(isSSR ? false : window.innerWidth < MOBILE_BREAKPOINT);
  /**
   * Reference values for the image height and top position.
   */

  var height = useRef(0);
  var top = useRef(0);
  /**
   * State variable for checking if the image has been transformed
   * yet. Used to hide the image before initial transform, as not to
   * show the jitter.
   */

  var _useState = useState(false),
      isTransformed = _useState[0],
      setIsTransformed = _useState[1];
  /**
   * Memoized values and the rendered style for the image
   * wrapper rotation effect.
   */


  var ry = useMemo(function () {
    return getRandomFloat(-0.5, 0.5);
  }, []);
  var rz = useMemo(function () {
    return getRandomFloat(-0.5, 0.5);
  }, []);
  var rotationInsenity = useMemo(function () {
    return Math.min(1, Math.max(0, intensity)) * MAX_ROTATION;
  }, [intensity]);
  var itemRotation = useRenderedStyle(rotationInsenity, function (fromValue, toValue) {
    return map(top.current - window.pageYOffset, window.innerHeight * 1.5, -1.25 * height.current, fromValue, toValue);
  });
  /**
   * Memoized values and the rendered style for the inner image
   * translation Y (for parallax effect).
   */

  var isLandscape = useMemo(function () {
    return image.width > image.height;
  }, [image]);
  var heightRatio = useMemo(function () {
    return image.width / image.height;
  }, [image]);
  var widthRatio = useMemo(function () {
    return isLandscape ? 1 / heightRatio : heightRatio;
  }, [heightRatio, isLandscape]);
  var parallaxIntensity = useMemo(function () {
    return Math.abs(rotationInsenity) * 1.5;
  }, [rotationInsenity]);
  var innerTranslationY = useRenderedStyle(parallaxIntensity, function (fromValue, toValue) {
    return map(top.current - window.pageYOffset, window.innerHeight, -1 * height.current, fromValue, toValue);
  }, true);
  /**
   * Updates the current size and position of the container.
   */

  var updateSize = function updateSize() {
    var rect = imgWrapperRef.current.getBoundingClientRect();
    height.current = rect.height;
    top.current = window.pageYOffset + rect.top;
  };
  /**
   * Updates the transform styles for the image and the
   * image wrapper.
   */


  var updateTransformStyles = useCallback(function () {
    var translateY = innerTranslationY.value.current;
    var rotateY = ry;
    var rotateZ = rz;
    var rotateDeg = itemRotation.value.current;
    var captionTranslateY = translateY * -3;

    if (isMobile.current) {
      translateY *= -1;
      rotateY = 0;
      rotateZ = 0;
      rotateDeg = 0;
      captionTranslateY = 0;
    }

    imgRef.current.style.transform = "translate3d(0, " + translateY + "px, 0)";
    imgWrapperRef.current.style.transform = "rotate3d(1, " + rotateY + ", " + rotateZ + ", " + rotateDeg + "deg)";
    captionRef.current.style.transform = "translate3d(0, " + captionTranslateY + "px, 0)";

    if (!isTransformed) {
      setIsTransformed(true);
    }
  }, [isTransformed]);
  /**
   * Updates the render styles of the images to match the
   * current scroll position.
   */

  var render = function render() {
    if (!isMobile.current) {
      itemRotation.render();
    }

    innerTranslationY.render();
    updateTransformStyles();
  };
  /**
   * Updates the hooked rendered styles, which are then used
   * to update the transform styles on each element.
   */


  var updateStyles = function updateStyles() {
    if (!isMobile.current) {
      itemRotation.update();
    }

    innerTranslationY.update();
    updateTransformStyles();
  };
  /**
   * Event handler for window resize. Checks if the device is mobile
   * sized, then updates the size and styles.
   */


  var onResize = function onResize() {
    isMobile.current = window.innerWidth < MOBILE_BREAKPOINT;
    updateSize();
    updateStyles();
  };
  /**
   * Event handler for the intersection observer. Sets whether the
   * element is currently visible or not.
   *
   * @param {object[]} entries the entries within the observer
   */


  var onObserve = function onObserve(entries) {
    entries.forEach(function (entry) {
      return isVisible.current = entry.intersectionRatio > 0;
    });
  };

  useEffect(function () {
    if (imgWrapperRef.current && isLoaded) {
      onResize();
      observer.current = new IntersectionObserver(onObserve);
      observer.current.observe(imgWrapperRef.current);
      window.addEventListener('resize', onResize);
      return function () {
        if (observer.current) {
          observer.current.disconnect();
          observer.current = null;
        }

        window.removeEventListener('resize', onResize);
      };
    }
  }, [isLoaded]);
  useImperativeHandle(ref, function () {
    return {
      imgEl: imgRef.current,
      isInsideViewport: isInsideViewport,
      isVisible: isVisible,
      render: render,
      update: updateStyles
    };
  }, [isInsideViewport, isVisible, render, updateStyles]);
  return React.createElement("figure", className(styles.figure, isLandscape && styles.landscape), React.createElement("div", Object.assign({
    ref: imgWrapperRef
  }, className(styles.figureImage, isTransformed && styles.figureImageLoaded), {
    style: {
      '--height-ratio': heightRatio,
      '--width-ratio': widthRatio
    }
  }), React.createElement("div", {
    ref: imgRef,
    className: styles.image,
    style: {
      backgroundImage: "url(" + url + ")",
      '--parallax': parallaxIntensity + "px"
    }
  })), React.createElement("div", {
    className: styles.figureImageFallback
  }, React.createElement("img", {
    alt: alt,
    src: url
  })), (captionBody || captionHeading) && React.createElement("figcaption", {
    ref: captionRef,
    className: styles.figureCaption
  }, captionHeading && React.createElement("h6", {
    className: styles.figureCaptionHeading
  }, captionHeading), captionBody && React.createElement("div", {
    className: styles.figureCaptionBody
  }, renderText(captionBody))));
});
Figure.displayName = 'Figure';
Figure.propTypes = {
  alt: PropTypes.string.isRequired,
  captionBody: PropTypes.object.isRequired,
  captionHeading: PropTypes.string,
  image: PropTypes.shape({
    details: PropTypes.shape({
      image: PropTypes.shape({
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired
      }).isRequired
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  intensity: PropTypes.number,
  isLoaded: PropTypes.bool
};
Figure.defaultProps = {
  intensity: 0.4,
  isLoaded: false
};
export default Figure;