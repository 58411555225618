import React from 'react';
import PropTypes from 'prop-types';
import { Footer, Link, Nav } from 'components';
import styles from './layout.module.scss';

var Layout = function Layout(_ref) {
  var children = _ref.children,
      location = _ref.location;
  return React.createElement("div", {
    className: styles.page
  }, React.createElement(Link, {
    to: "#content",
    className: styles.skipToContent
  }, "Skip to Content"), React.createElement(Nav, {
    location: location
  }), React.createElement("main", {
    className: styles.main,
    id: "content"
  }, children), React.createElement(Footer, null));
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
};
export default Layout;