import "core-js/modules/es6.object.assign";
import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import styles from './link.module.scss';
var Link = forwardRef(function (_ref, ref) {
  var activeClassName = _ref.activeClassName,
      children = _ref.children,
      className = _ref.className,
      newTab = _ref.newTab,
      onClick = _ref.onClick,
      onFocus = _ref.onFocus,
      onMouseOver = _ref.onMouseOver,
      tabIndex = _ref.tabIndex,
      title = _ref.title,
      to = _ref.to;
  var isInternalLink = useMemo(function () {
    var isInternal = /^\/(?!\/)/.test(to);
    var isFile = /\.[0-9a-z]+$/i.test(to);
    return isInternal && !isFile;
  }, [to]);
  var props = {
    className: className,
    onClick: onClick,
    onFocus: onFocus,
    onMouseOver: onMouseOver,
    ref: ref,
    tabIndex: tabIndex
  };

  if (title) {
    props.title = title;
  }

  if (isInternalLink) {
    return React.createElement(GatsbyLink, Object.assign({
      to: to,
      activeClassName: activeClassName
    }, props), children);
  }

  if (newTab) {
    props.target = '_blank';
    props.className = [props.className, styles.externalLink].join(' ');
  }

  return React.createElement("a", Object.assign({
    href: to
  }, props), children);
});
Link.displayName = 'Link';
Link.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseOver: PropTypes.func,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  to: PropTypes.string.isRequired
};
Link.defaultProps = {
  activeClassName: '',
  className: '',
  newTab: false,
  tabIndex: 0
};
export default Link;