import staticQueryData from "../../../public/static/d/1887037180.json";
import React, { useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Link } from 'components';
import NavModal from './NavModal';
import NavIntro from './NavIntro';
import NavToggle from './NavToggle';
import { useDetectSSR, useNavLinks, useStore } from 'hooks';
import { HashedLogo } from 'svgs';
import { STORE_MODULES } from 'utils/constants';
import styles from './nav.module.scss';
var MOBILE_BREAKPOINT = parseInt(styles.varMobileBreakpoint);

var Nav = function Nav() {
  var siteName = staticQueryData.data.site.siteName;

  var _useStore = useStore(STORE_MODULES.NAV),
      intro = _useStore[0].intro;

  var navLinks = useNavLinks();
  var isSSR = useDetectSSR();
  var modalRef = useRef();

  var _useState = useState(false),
      isNavModalOpen = _useState[0],
      setIsNavModalOpen = _useState[1];
  /**
   * Toggles the nav modal visibility. Also toggles the body
   * scroll lock when the nav modal is open.
   *
   * @param {boolean} toggle whether to open or close modal
   */


  var toggleNavModal = function toggleNavModal(toggle) {
    setIsNavModalOpen(toggle);

    if (toggle) {
      disableBodyScroll(modalRef.current);
    } else {
      enableBodyScroll(modalRef.current);
    }
  };
  /**
   * Closes the modal when the window size is greater than
   * the mobile breakpoint (no need for modal anymore).
   */


  var handleResize = function handleResize() {
    if (isNavModalOpen && window.innerWidth > MOBILE_BREAKPOINT) {
      toggleNavModal(false);
    }
  };

  useEffect(function () {
    if (!isSSR) {
      handleResize();
      window.addEventListener('resize', handleResize);
      return function () {
        window.removeEventListener('resize', handleResize);
        clearAllBodyScrollLocks();
      };
    }
  }, [isSSR]);
  return React.createElement("nav", {
    className: styles.nav
  }, React.createElement("div", {
    className: styles.logoIntroWrapper
  }, React.createElement(Link, {
    to: "/",
    title: siteName,
    className: styles.logoLink,
    onClick: function onClick() {
      return toggleNavModal(false);
    }
  }, React.createElement(HashedLogo, {
    className: styles.logoSvg
  }), React.createElement("span", {
    className: styles.visuallyHidden
  }, siteName)), intro && React.createElement(NavIntro, {
    intro: intro
  })), React.createElement(NavToggle, {
    isNavModalOpen: isNavModalOpen,
    toggleNavModal: toggleNavModal
  }), React.createElement(NavModal, {
    ref: modalRef,
    isNavModalOpen: isNavModalOpen,
    links: navLinks,
    toggleNavModal: toggleNavModal
  }), React.createElement("ul", {
    className: styles.navLinkList
  }, navLinks.map(function (_ref, i) {
    var text = _ref.text,
        to = _ref.to;
    return React.createElement("li", {
      key: i,
      className: styles.navLinkItem
    }, React.createElement(Link, {
      to: to,
      className: styles.navLink,
      activeClassName: styles.navLinkActive
    }, React.createElement("span", {
      className: styles.navLinkText
    }, text)));
  })));
};

export default Nav;