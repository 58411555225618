import "core-js/modules/es6.object.assign";

var _renderNode;

import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { Link } from 'components';
import { getTeamMemberRoute } from 'utils';
import { ENTRY_TYPES, ENTRY_PAGE_TYPE_TO_ROUTE, LANG } from 'utils/constants';
/**
 * Serializes the target from an entry hyperlink (internal
 * content) to form the correct link route to the entry in
 * the site.
 *
 * @param {object} target the target from the entry hyperlink
 * @returns {string} the corresponding link
 */

var getEntryLink = function getEntryLink(target) {
  var entryType = target.sys.contentType.sys.id;

  if (entryType === ENTRY_TYPES.TEAM_MEMBER) {
    return getTeamMemberRoute(target.fields.slug[LANG.EN_US]);
  }

  return ENTRY_PAGE_TYPE_TO_ROUTE[entryType] || '/';
};
/**
 * Helper function for rendering an entry hyperlink. Accepts
 * a render function and passes in the correct route and
 * children text.
 *
 * @param {function} render the render function for the entry
 * hyperlink
 * @returns the render function call
 */


export var renderEntryHyperlink = function renderEntryHyperlink(render) {
  return function (_ref, children) {
    var target = _ref.data.target;
    var to = getEntryLink(target);
    return render(to, children);
  };
};
/**
 * Helper function for rendering a hyperlink. Accepts a render
 * function and passes in the url and the children text.
 *
 * @param {function} render the render function for the
 * hyperlink
 * @returns the render function call
 */

export var renderHyperlink = function renderHyperlink(render) {
  return function (_ref2, children) {
    var uri = _ref2.data.uri;
    return render(uri, children);
  };
};
/**
 * Default options for the render text function.
 */

var DEFAULT_OPTIONS = {
  renderMark: {},
  renderNode: (_renderNode = {}, _renderNode[INLINES.ENTRY_HYPERLINK] = renderEntryHyperlink(function (to, text) {
    return React.createElement(Link, {
      to: to
    }, text);
  }), _renderNode[INLINES.HYPERLINK] = renderHyperlink(function (to, text) {
    return React.createElement(Link, {
      to: to,
      newTab: true
    }, text);
  }), _renderNode),
  renderText: function renderText(text) {
    return text;
  }
};
/**
 * Renders rich text from Contentful into React components.
 *
 * @param {object} document the rich text document
 * @param {object} document.json the json respresentation of
 * the rich text document
 * @param {object} [options={}] any render options
 * @returns {object[]} the final rendered rich text
 */

var renderText = function renderText(document, options) {
  if (options === void 0) {
    options = {};
  }

  return documentToReactComponents(document.json, {
    renderMark: Object.assign({}, DEFAULT_OPTIONS.renderMark, options.renderMark || {}),
    renderNode: Object.assign({}, DEFAULT_OPTIONS.renderNode, options.renderNode || {}),
    renderText: options.renderText || DEFAULT_OPTIONS.renderText
  });
};

export default renderText;