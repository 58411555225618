import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import staticQueryData from "../../../public/static/d/2198116134.json";
import React, { useMemo } from 'react';
import { ContactLinks, Link } from 'components';
import { useNavLinks } from 'hooks';
import { className, renderText } from 'utils';
import styles from './footer.module.scss';

var Footer = function Footer() {
  var links = useNavLinks();
  var _staticQueryData$data = staticQueryData.data.footer,
      about = _staticQueryData$data.about,
      copyright = _staticQueryData$data.copyright,
      email = _staticQueryData$data.email,
      linkedIn = _staticQueryData$data.linkedIn,
      medium = _staticQueryData$data.medium,
      offices = _staticQueryData$data.offices,
      twitter = _staticQueryData$data.twitter;
  /**
   * Memoized value for the locations of the offices in the footer.
   */

  var locations = useMemo(function () {
    return offices.map(function (_ref) {
      var address = _ref.address.address,
          addressLink = _ref.addressLink,
          name = _ref.name,
          telephone = _ref.telephone;
      return {
        address: React.createElement(Link, {
          to: addressLink,
          className: styles.addressLink,
          newTab: true
        }, address.split('\n').map(function (text, i) {
          return React.createElement("span", {
            key: i,
            className: styles.addressTextWrapper
          }, React.createElement("span", {
            className: styles.addressText
          }, text));
        })),
        name: name,
        telephone: telephone
      };
    });
  });
  return React.createElement("footer", {
    className: styles.container
  }, React.createElement("div", {
    className: styles.content
  }, React.createElement("div", {
    className: styles.footerSection
  }, React.createElement("div", className(styles.footerBlock, styles.footerAbout), renderText(about)), React.createElement("div", {
    className: styles.footerBlock
  }, React.createElement("nav", null, links.map(function (_ref2, i) {
    var text = _ref2.text,
        to = _ref2.to;
    return React.createElement("div", {
      key: i,
      className: styles.navWrapper
    }, React.createElement(Link, {
      to: to,
      className: styles.navItem,
      activeClassName: styles.navItemActive
    }, text));
  })))), React.createElement("div", {
    className: styles.footerSection
  }, locations.map(function (_ref3, i) {
    var address = _ref3.address,
        name = _ref3.name,
        telephone = _ref3.telephone;
    return React.createElement("div", {
      key: i,
      className: styles.footerBlock
    }, i === 0 && React.createElement("span", {
      className: styles.locationLine
    }), React.createElement("h5", {
      className: styles.location
    }, name), React.createElement("address", {
      className: styles.addressBlock
    }, React.createElement("p", {
      className: styles.address
    }, address), telephone && React.createElement(Link, {
      to: "tel:" + telephone,
      className: styles.phone
    }, telephone)));
  })), React.createElement("div", {
    className: styles.footerSection
  }, React.createElement("div", {
    className: styles.footerBlock
  }, React.createElement(ContactLinks, {
    email: email,
    linkedIn: linkedIn,
    medium: medium,
    twitter: twitter
  })), React.createElement("div", {
    className: styles.footerBlock
  }, React.createElement("p", {
    className: styles.footerCopyright
  }, copyright)))));
};

export default Footer;