import React from 'react';
import PropTypes from 'prop-types';
import sharedStyles from 'svgs/sharedStyles.module.scss';

var Email = function Email(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", {
    className: customClassName
  }, React.createElement("svg", {
    className: sharedStyles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22 22"
  }, React.createElement("path", {
    fillRule: "evenodd",
    d: "M2,9 L11,13 L20,9 L20,18 L2,18 L2,9 Z M2,5 L20,5 L20,7 L11,11 L2,7 L2,5 Z"
  })));
};

Email.propTypes = {
  className: PropTypes.string
};
export default Email;