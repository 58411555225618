import React from 'react';
import { className } from 'utils';
import styles from './hash.module.scss';

var Hash = function Hash(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", className(styles.hash, customClassName), React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 98.01 66.35"
  }, React.createElement("path", {
    className: styles.path,
    d: "M94.54,26.46,98,12.56H81.66L84.8,0H65.37L62.24,12.56H46.05L49.19,0H29.77L26.63,12.56H10.31L6.83,26.46H23.15L20,39H3.71L0,53.8H16.32L13.18,66.35H32.6L35.74,53.8H51.93L48.78,66.35H68.21L71.35,53.8H87.71L91.41,39H75.06l3.12-12.51ZM39.45,39l3.13-12.51H58.76L55.63,39Z"
  })));
};

export default Hash;