import React from 'react';
import PropTypes from 'prop-types';
import { StoreProvider } from 'store';
import 'style/index.scss';

var wrapRootElement = function wrapRootElement(_ref) {
  var element = _ref.element;
  return React.createElement(StoreProvider, null, element);
};

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired
};
export default wrapRootElement;