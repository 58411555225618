import { useEffect } from 'react';
import { useStore } from 'hooks';
import { UPDATE_NAV_INTRO } from 'store/types';
import { STORE_MODULES } from 'utils/constants';
/**
 * React hook for updating the intro in the `Nav`.
 *
 * @param {object} [intro] the intro to update the store
 * with
 */

var useNavIntro = function useNavIntro(intro) {
  var _useStore = useStore(STORE_MODULES.NAV),
      dispatch = _useStore[1];

  useEffect(function () {
    dispatch({
      type: UPDATE_NAV_INTRO,
      payload: intro || null
    });
  }, []);
};

export default useNavIntro;