import React from 'react';
import PropTypes from 'prop-types';
import styles from './statement.module.scss';

var Statement = function Statement(_ref) {
  var children = _ref.children,
      title = _ref.title;
  return React.createElement("div", null, React.createElement("h2", {
    className: styles.heading
  }, title), React.createElement("div", {
    className: styles.body
  }, children));
};

Statement.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};
export default Statement;