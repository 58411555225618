import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './cursorExternal.module.scss';

var CursorExternal = function CursorExternal(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", className(styles.container, customClassName), React.createElement("svg", {
    className: styles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 36 36"
  }, React.createElement("g", {
    transform: "translate(10 10)"
  }, React.createElement("path", {
    className: styles.icon,
    d: "M0,14 L0,12 L2,12 L4,12 L4,14 L4,16 L2,16 L0,16 L0,14 Z M6,12 L4,12 L4,10 L4,8 L6,8 L8,8 L8,10 L8,12 L6,12 Z"
  }), React.createElement("path", {
    className: styles.icon,
    d: "M4,2 L4,0 L6,0 L8,0 L10,0 L12,0 L14,0 L16,0 L16,2 L16,4 L16,6 L16,8 L16,10 L16,12 L14,12 L12,12 L12,10 L12,8 L12,6 L12,4 L10,4 L8,4 L6,4 L4,4 L4,2 Z"
  }))));
};

CursorExternal.propTypes = {
  className: PropTypes.string
};
export default CursorExternal;