import { useContext } from 'react';
import { storeContext } from 'store';
/**
 * React hook for retrieving the global store state and
 * dispatch function. Can scope to a given module.
 *
 * @param {string} [moduleName] the name of the module
 * to scope to
 * @returns {[object, function]} the scoped store and
 * dispatch function
 */

var useStore = function useStore(moduleName) {
  var _useContext = useContext(storeContext),
      store = _useContext[0],
      dispatch = _useContext[1];

  var scopedStore = store;

  if (moduleName) {
    scopedStore = store[moduleName];

    if (!scopedStore) {
      console.warn('This module does not exist in the store');
    }
  }

  return [scopedStore, dispatch];
};

export default useStore;