import React, { forwardRef, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Power4 } from 'gsap';
import { Link } from 'components';
import { KEY_CODES } from 'utils/constants';
import { useArrayRef } from 'hooks';
import styles from './navModal.module.scss'; // Animation variables

var EASE = Power4.easeInOut;
var EASEOUT = Power4.easeOut;
var LEAVE = {
  autoAlpha: 0,
  top: -10,
  ease: EASE
};
var ENTER = {
  autoAlpha: 1,
  top: 0,
  ease: EASEOUT
};
var DURATION = 0.6;
var NavModal = forwardRef(function (_ref, ref) {
  var links = _ref.links,
      isNavModalOpen = _ref.isNavModalOpen,
      toggleNavModal = _ref.toggleNavModal;
  var navModalRef = useRef(null);

  var _useArrayRef = useArrayRef(),
      navItemsRef = _useArrayRef[0],
      setNavItemRef = _useArrayRef[1];

  var toggleModal = function toggleModal(modalTag) {
    if (isNavModalOpen) {
      // Show nav
      TweenMax.to(modalTag, DURATION, ENTER); // Stagger show nav elements

      navItemsRef.current.forEach(function (item, index) {
        TweenMax.fromTo(item, index / 3, LEAVE, ENTER).delay(0.005 * index);
      });
    } else {
      TweenMax.to(modalTag, DURATION, LEAVE);
    }
  };

  var handleKeyup = function handleKeyup(e) {
    if (e.keyCode === KEY_CODES.ESCAPE) {
      toggleNavModal(false);
    }
  };

  useEffect(function () {
    // Hide modal on load
    TweenMax.to(navModalRef.current, 0, LEAVE);
  }, []);
  useEffect(function () {
    var navModalTag = navModalRef.current; // Toggle the modal based on `isNavModalOpen` prop

    toggleModal(navModalTag); // Close modal on esc

    if (isNavModalOpen) {
      document.addEventListener('keyup', handleKeyup);
    }

    return function () {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [isNavModalOpen]);
  useImperativeHandle(ref, function () {
    return navModalRef.current;
  }, [navModalRef]);
  return React.createElement("ul", {
    ref: navModalRef,
    className: styles.navLinkList
  }, links.map(function (_ref2, i) {
    var text = _ref2.text,
        to = _ref2.to;
    return React.createElement("li", {
      key: i,
      className: styles.navLinkItem
    }, React.createElement(Link, {
      to: to,
      ref: setNavItemRef(i),
      onClick: function onClick() {
        return toggleNavModal(false);
      },
      className: styles.navLink,
      activeClassName: styles.navLinkActive
    }, React.createElement("span", {
      className: styles.navLinkText
    }, text)));
  }));
});
NavModal.displayName = 'NavModal';
NavModal.propTypes = {
  links: PropTypes.array.isRequired,
  isNavModalOpen: PropTypes.bool.isRequired,
  toggleNavModal: PropTypes.func.isRequired
};
export default NavModal;