import staticQueryData from "../../public/static/d/2368876786.json";
import { useEffect, useState } from 'react';
import { INLINES } from '@contentful/rich-text-types';
import renderText, { renderEntryHyperlink, renderHyperlink } from 'utils/renderText';
/**
 * React hook for fetching nav links from the Site
 * Contentful model.
 */

var useNavLinks = function useNavLinks() {
  var links = staticQueryData.data.site.links;

  var _useState = useState([]),
      navLinks = _useState[0],
      setNavLinks = _useState[1];
  /**
   * Retrieves the nav links to render from the the links
   * body in the Contentful Site model.
   *
   * @returns {object[]} the nav links array
   */


  var getNavLinks = function getNavLinks() {
    var _renderNode;

    var navLinks = [];
    /**
     * Adds a link to the navLinks array.
     *
     * @param {string|string[]} text the text to display
     * @param {string} to the link to redirect to
     */

    var addLink = function addLink(to, text) {
      return navLinks.push({
        text: text,
        to: to
      });
    };

    var options = {
      renderNode: (_renderNode = {}, _renderNode[INLINES.ENTRY_HYPERLINK] = renderEntryHyperlink(addLink), _renderNode[INLINES.HYPERLINK] = renderHyperlink(addLink), _renderNode)
    };
    renderText(links, options);
    return navLinks;
  };

  useEffect(function () {
    setNavLinks(getNavLinks());
  }, [links]);
  return navLinks;
};

export default useNavLinks;