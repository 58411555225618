import React from 'react';
import PropTypes from 'prop-types';
import sharedStyles from 'svgs/sharedStyles.module.scss';

var Medium = function Medium(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", {
    className: customClassName
  }, React.createElement("svg", {
    className: sharedStyles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22 22"
  }, React.createElement("path", {
    d: "M4.24049235,7.05996472 C4.26396699,6.82802748 4.17551923,6.59878332 4.00232978,6.44268077 L2.23816257,4.31746032 L2.23816257,4 L7.71590175,4 L11.949903,13.2857142 L15.6722959,4 L20.8942308,4 L20.8942308,4.31746032 L19.3858678,5.76366843 C19.2558304,5.86279053 19.1913252,6.02570474 19.218272,6.18694886 L19.218272,16.8130512 C19.1913252,16.9742953 19.2558304,17.1372094 19.3858678,17.2363316 L20.8589475,18.6825397 L20.8589475,19 L13.4494452,19 L13.4494452,18.6825397 L14.9754499,17.2010582 C15.125404,17.0511463 15.125404,17.0070547 15.125404,16.7777778 L15.125404,8.18871253 L10.8825819,18.9647266 L10.3092275,18.9647266 L5.36955936,8.18871253 L5.36955936,15.4109347 C5.3283742,15.7145773 5.42921922,16.0202788 5.64300527,16.2398589 L7.62769338,18.6472663 L7.62769338,18.9647266 L2,18.9647266 L2,18.6472663 L3.98468811,16.2398589 C4.19691761,16.0199148 4.29188228,15.7121853 4.24049235,15.4109347 L4.24049235,7.05996472 Z"
  })));
};

Medium.propTypes = {
  className: PropTypes.string
};
export default Medium;