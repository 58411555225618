import React from 'react';
import PropTypes from 'prop-types';
import { renderText } from 'utils';
import styles from './navIntro.module.scss';

var NavIntro = function NavIntro(_ref) {
  var intro = _ref.intro;
  return React.createElement("div", {
    className: styles.pageTopper
  }, React.createElement("div", {
    className: styles.textContent
  }, renderText(intro)));
};

NavIntro.propTypes = {
  intro: PropTypes.shape({
    json: PropTypes.object.isRequired
  }).isRequired
};
export default NavIntro;