import React from 'react';
import PropTypes from 'prop-types';
import sharedStyles from 'svgs/sharedStyles.module.scss';

var YouTube = function YouTube(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", {
    className: customClassName
  }, React.createElement("svg", {
    className: sharedStyles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22 22"
  }, React.createElement("path", {
    d: "M20.9823528,6.34705881 C21.4411763,8.02352938 21.4236193,11.517647 21.4236193,11.517647 C21.4236193,11.517647 21.4236193,14.9941176 20.9823528,16.6705881 C20.735294,17.5882352 20.0117646,18.3117646 19.0941175,18.5588234 C17.4176469,19 10.7117646,19 10.7117646,19 C10.7117646,19 4.02352938,19 2.32941175,18.5411764 C1.4117647,18.2941175 0.688235289,17.5705881 0.441176467,16.6529411 C-2.52242671e-13,14.9941176 -2.52242671e-13,11.5 -2.52242671e-13,11.5 C-2.52242671e-13,11.5 -2.52242671e-13,8.02352938 0.441176467,6.34705881 C0.688235289,5.42941175 1.42941175,4.68823529 2.32941175,4.44117647 C4.00588232,4 10.7117646,4 10.7117646,4 C10.7117646,4 17.4176469,4 19.0941175,4.45882353 C20.0117646,4.70588235 20.735294,5.42941175 20.9823528,6.34705881 Z M13.5764705,11.2117647 L8,8 L8,14.4235294 L13.5764705,11.2117647 Z"
  })));
};

YouTube.propTypes = {
  className: PropTypes.string
};
export default YouTube;