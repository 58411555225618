import "core-js/modules/es7.array.flat-map";
import staticQueryData from "../../../public/static/d/932473182.json";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

var SEO = function SEO(_ref) {
  var description = _ref.description,
      pathname = _ref.pathname,
      shareImage = _ref.shareImage,
      title = _ref.title,
      useLargeImageCard = _ref.useLargeImageCard;
  var _staticQueryData$data = staticQueryData.data.site,
      seo = _staticQueryData$data.seo,
      siteName = _staticQueryData$data.siteName,
      siteUrl = _staticQueryData$data.siteUrl,
      twitterHandle = _staticQueryData$data.twitterHandle;
  var metaTitle = useMemo(function () {
    return title === seo.title ? seo.title : title + " | " + seo.title;
  }, [title, seo.title]);
  var metaDescription = useMemo(function () {
    return description && description.description ? description.description : seo.description.description;
  }, [description, seo.description]);
  var metaUrl = useMemo(function () {
    return "" + siteUrl + pathname;
  }, [pathname, siteUrl]);
  var metaImage = useMemo(function () {
    return shareImage || seo.shareImage;
  }, [shareImage, seo.shareImage]);
  var metaImageSrc = useMemo(function () {
    return metaImage && metaImage.file ? metaImage.file.url : '';
  }, [metaImage]);
  var metaImageAlt = useMemo(function () {
    return metaImage && metaImage.file ? metaImage.title : '';
  }, [metaImage]);
  var metaCardType = useMemo(function () {
    var useLarge = useLargeImageCard !== null ? useLargeImageCard : seo.useLargeImageCard;
    return useLarge ? 'summary_large_image' : 'summary';
  }, [useLargeImageCard, seo.useLargeImageCard]);
  return React.createElement(Helmet, {
    htmlAttributes: {
      lang: 'en'
    },
    title: metaTitle,
    meta: [{
      name: "description",
      content: metaDescription
    },
    /* OG Meta */
    {
      property: "og:locale",
      content: "en_US"
    }, {
      property: "og:site_name",
      content: siteName
    }, {
      property: "og:type",
      content: "website"
    },
    /* Twitter Meta */
    {
      name: "twitter:card",
      content: metaCardType
    }, {
      name: "twitter:creator",
      content: twitterHandle
    }, {
      name: "twitter:site",
      content: twitterHandle
    }].concat(['og', 'twitter'].flatMap(function (type) {
      return [{
        property: type + ":description",
        content: metaDescription
      }, {
        property: type + ":image",
        content: metaImageSrc
      }, {
        property: type + ":image:alt",
        content: metaImageAlt
      }, {
        property: type + ":title",
        content: metaTitle
      }, {
        property: type + ":url",
        content: metaUrl
      }];
    }))
  });
};

SEO.propTypes = {
  description: PropTypes.shape({
    description: PropTypes.string.isRequired
  }),
  pathname: PropTypes.string.isRequired,
  shareImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }),
  title: PropTypes.string.isRequired,
  useLargeImageCard: PropTypes.bool
};
export default SEO;