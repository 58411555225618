import "core-js/modules/es6.object.assign";
import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './activeArrow.module.scss';

var ActiveArrow = function ActiveArrow(_ref) {
  var customClassName = _ref.className;
  return React.createElement("svg", Object.assign({}, className(styles.svgContainer, customClassName), {
    width: "20px",
    height: "20px",
    viewBox: "0 0 20 20"
  }), React.createElement("rect", {
    className: styles.tip,
    x: "8",
    y: "8",
    width: "4",
    height: "4"
  }), React.createElement("g", {
    className: styles.middleGroup
  }, React.createElement("rect", {
    x: "4",
    y: "12",
    width: "4",
    height: "4"
  }), React.createElement("rect", {
    x: "4",
    y: "4",
    width: "4",
    height: "4"
  })), React.createElement("g", {
    className: styles.leftGroup
  }, React.createElement("rect", {
    y: "16",
    width: "4",
    height: "4"
  }), React.createElement("rect", {
    width: "4",
    height: "4"
  })));
};

ActiveArrow.propTypes = {
  className: PropTypes.string
};
export default ActiveArrow;