import React from 'react';
import PropTypes from 'prop-types';
import sharedStyles from 'svgs/sharedStyles.module.scss';

var Twitter = function Twitter(_ref) {
  var customClassName = _ref.className;
  return React.createElement("div", {
    className: customClassName
  }, React.createElement("svg", {
    className: sharedStyles.svg,
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22 22"
  }, React.createElement("path", {
    d: "M19.8214286,5.72082635 C19.1428571,6.00654064 18.4285714,6.22082635 17.7142857,6.29225492 C18.4642857,5.82796921 19.0357143,5.1136835 19.3214286,4.25654064 C18.6071429,4.68511207 17.8214286,4.97082635 17,5.14939778 C15.6071429,3.68511207 13.2857143,3.6136835 11.8214286,4.97082635 C10.8571429,5.8636835 10.4642857,7.18511207 10.75,8.47082635 C7.82142857,8.3636835 5.07142857,6.97082635 3.21428571,4.68511207 C2.25,6.3636835 2.75,8.50654064 4.35714286,9.57796921 C3.75,9.54225492 3.17857143,9.39939778 2.67857143,9.1136835 L2.67857143,9.14939778 C2.67857143,10.8993978 3.89285714,12.3993978 5.60714286,12.7565406 C5.28571429,12.8279692 4.96428571,12.8993978 4.64285714,12.8993978 C4.42857143,12.8993978 4.17857143,12.8636835 3.96428571,12.8279692 C4.42857143,14.3279692 5.82142857,15.3279692 7.39285714,15.3636835 C6.10714286,16.3993978 4.5,16.9351121 2.85714286,16.9351121 C2.57142857,16.9351121 2.28571429,16.8993978 2,16.8993978 C3.67857143,17.9708264 5.60714286,18.5422549 7.60714286,18.5422549 C14.3571429,18.5422549 18.0357143,12.9708264 18.0357143,8.1136835 L18.0357143,7.64939778 C18.7142857,7.07796921 19.3214286,6.47082635 19.8214286,5.72082635 Z"
  })));
};

Twitter.propTypes = {
  className: PropTypes.string
};
export default Twitter;