import React from 'react';
import PropTypes from 'prop-types';
import styles from './locationLine.module.scss';

var LocationLine = function LocationLine(_ref) {
  var from = _ref.from,
      to = _ref.to;
  return React.createElement("div", {
    className: styles.centerWrapper
  }, React.createElement("div", {
    className: styles.locationContainer
  }, React.createElement("p", {
    className: styles.destination
  }, from), React.createElement("svg", {
    className: styles.svgContainer,
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("line", {
    className: styles.line,
    x1: "1%",
    y1: "95%",
    x2: "99%",
    y2: "5%"
  })), React.createElement("p", {
    className: styles.destination
  }, to)));
};

LocationLine.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};
export default LocationLine;