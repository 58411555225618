import "core-js/modules/es6.object.assign";
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './grid.module.scss'; // eslint-disable-next-line react/display-name

var Grid = forwardRef(function (_ref, ref) {
  var children = _ref.children,
      customClassName = _ref.className,
      hasMaxWidth = _ref.hasMaxWidth,
      Tag = _ref.tag;
  return React.createElement(Tag, Object.assign({}, className(styles.gridParent, hasMaxWidth && styles.hasMaxWidth, customClassName), {
    ref: ref
  }), children);
});
Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasMaxWidth: PropTypes.bool,
  tag: PropTypes.string
};
Grid.defaultProps = {
  hasMaxWidth: false,
  tag: 'div'
};
export default Grid;