import { useCallback, useRef, useMemo } from 'react';
/**
 * Helper function for performing linear interpolation.
 *
 * @param {number} start the first value
 * @param {number} stop the second value
 * @param {number} amt the amount to interpolate between
 * the two points, between 0 and 1
 */

var lerp = function lerp(start, stop, amt) {
  return (1 - amt) * start + amt * stop;
};
/**
 * React hook for managing a single rendered style.
 *
 * @param {function} getToValue callback function for
 * retrieving the rendered style's max value
 * @param {function} getMappedValue callback function for
 * retrieving the rendered style's current value, given a
 * max and min value
 * @param {boolean} reverseEval whether to reverse the evaluation
 * of the max/min in the `getValue` function
 * @param {any[]} [deps=[]] the dependency array for the
 * `getValue` function
 */


var useRenderedStyle = function useRenderedStyle(toValue, getMappedValue, reverseEval) {
  if (reverseEval === void 0) {
    reverseEval = false;
  }

  var current = useRef(0);
  var next = useRef(0);
  var ease = useRef(0.1);
  var fromValue = useMemo(function () {
    return -1 * toValue;
  }, [toValue]);
  /**
   * Retrieves the next style value, using the passed
   * to and mapped values.
   */

  var getValue = useCallback(function () {
    var val = getMappedValue(fromValue, toValue);

    if (reverseEval) {
      return Math.max(Math.min(val, toValue), fromValue);
    } else {
      return Math.min(Math.max(val, toValue), fromValue);
    }
  }, [getMappedValue, fromValue, toValue]);
  /**
   * Retrieves the value for the next style value, then sets
   * the current value to a lerp between the current and next.
   */

  var render = useCallback(function () {
    next.current = getValue();
    current.current = lerp(current.current, next.current, ease.current);
  }, [getValue]);
  /**
   * Sets the current and next values to the next style value.
   */

  var update = useCallback(function () {
    next.current = current.current = getValue();
  }, [getValue]);
  return {
    render: render,
    update: update,
    value: current
  };
};

export default useRenderedStyle;