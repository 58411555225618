/**
 * Helper for React components for easily composing dynamic
 * className lists.
 *
 * This will only append classes of type string, making it
 * easy to write conditional statements in classes:
 *
 * ```js
 * <p {...className(styles.text, large && styles.textLarge)} />
 * ```
 *
 * @param  {...any} classes the classes to append to the
 * className field
 * @returns an object with the field `className` to spread on
 * a React component
 */
var className = function className() {
  for (var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++) {
    classes[_key] = arguments[_key];
  }

  return {
    className: classes.filter(function (className) {
      return typeof className === 'string';
    }).join(' ')
  };
};

export default className;