import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'components';

var wrapPageElement = function wrapPageElement(_ref) {
  var element = _ref.element,
      props = _ref.props;
  return React.createElement(Layout, props, element);
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.object.isRequired
};
export default wrapPageElement;